.modal-like_container {
  padding: 40px;

  .modal-like_title {
    color: #222222;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .modal-like_description {
    color: #222222;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-top: 24px;
  }
}

.modal-like_btn {
  width: 100%;
}

.like-container {
  display: inline-block;
  cursor: pointer;
}
