.page {
  opacity: 0;
}

.page-appear {
  opacity: 0;
}

.page-appear-active {
  opacity: 1;
  transition: opacity 800ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 800ms, transform 800ms;
}

.panel {
  opacity: 0;
}

.panel-appear {
  opacity: 0;
}

.panel-appear-active {
  opacity: 1;
  transition: opacity 100ms;
}

.panel-exit {
  opacity: 1;
}

.panel-exit-active {
  opacity: 0;
  transition: opacity 100ms, transform 100ms;
}
