@import "../../styles/shared/_variables.scss";

.resultsLabel {
  display: block;
  margin: 0 10px 20px 0;
  .resultsCount {
    @include sansbold();
  }

  .subLink {
    float: right;
    padding: 0;
  }
}

.pillPanel {
  margin-bottom: 20px;
}

.loadStatus {
  line-height: 50px;
  padding: 0 10px;
  text-align: right;

  @include media-breakpoint-only(lg) {
    text-align: center;
    padding: 10px 0;
  }

  @include media-breakpoint-down(sm) {
    text-align: center;
    padding: 10px 0;
  }
}

.eventGridRow {
  position: relative;
  .slds-spinner {
    top: 275px !important;

    @include media-breakpoint-down(sm) {
      top: 120px !important;
    }
  }
}

.loadMoreButton {
  width: 200px;
  .slds-spinner_inline {
    display: inline-block;
    margin-left: auto;
    height: 8px;
  }
}

#filterListEnd {
  margin-top: 200px;
}

.slds-spinner_container {
  z-index: 1000 !important;
}

/*.slds-spinner_inline {
  top: 10px;
  left:
}*/
