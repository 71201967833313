@import "../../../styles/shared/_variables.scss";

.coveo--pager {
    .page-link {
        color: $stratus;
        width: 44px;
        height: 44px;
        padding: 8px 0 0;
        margin: 0;
        border: none;
        border-bottom: 3px solid transparent;

        svg {
            width: 11px;
            height: 20px;
            vertical-align: middle;
        }

        &:hover {
            border-color: transparent;
            background: none;
            color: $stratus;
        }

        @include media-breakpoint-down(xxs) {
            width: 18px;

            svg {
                height: 14px;
            }
        }
    }

    .page-item {
        font-size: 14px;
        margin: 5px 6px;
        padding: 4px 8px;
        vertical-align: baseline;
        text-align: center;

        &:first-child, &:last-child {
            .page-link {
                border-radius: 0;
            }
        }

        &.disabled {
            .page-link {
                border-color: transparent;
            }
        }
        &.active {
            .page-link {
                color: $stratus;
                border-color: $stratus;
            }
        }
    }
}