@import "../../styles/shared/_variables.scss";

.appFooter {
  margin-top: 40px;
  min-height: 86px;
  background: $stratus;
  color: #fff;
  font-size: 11px;

  .row {
    padding-top: 20px;
  }

  a {
    color: #00a1e0;

    &:hover {
      color: #fff;
    }
  }

  .page-footer_links_list {
    padding: 0 0 7px;
    margin: 10px 0;

    .page-footer_links_item:first-child {
      margin-left: 0;
    }
    .page-footer_links_item {
      display: inline-block;
      margin: 5px 10px 0 10px;

      .footer-link-icon {
        width: 30px;
        height: 15px;
        margin-right: 5px;
        display: inline-block;
      }
    }
  }
}

.footer-hide {
  .appFooter {
    display: none;
  }
}
