@import "../../styles/shared/_variables.scss";

.navbar {
  height: 140px;
  padding: 1rem 1rem 1.125rem;

  background: #fff;
  border: 2px solid $salesforce-blue-mid;
  border-width: 0 0 2px 0;

  @include media-breakpoint-down(lg) {
    height: 95px;
  }

  @include media-breakpoint-down(sm) {
    top: 0px;
    padding: 0.75rem 1rem;
    height: 92px;
    border: none;
  }
}

a.navTitleLink {
  color: $stratus;

  &:hover,
  &:active {
    color: $stratus;
  }

  &:hover {
    color: $default-solid;
  }
}

.navbar-light {
  .navbar-brand {
    color: $stratus;

    &:hover,
    &:focus {
      color: $stratus;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $stratus;

      &:hover,
      &:focus {
        color: $default-solid;
      }
    }
  }
}

.navbar-brand {
  @include agbold();
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 0.25rem;
  margin-right: 52px;

  svg {
    height: 48px;
    margin-bottom: 20px;
    display: block;

    @include media-breakpoint-down(lg) {
      height: 40px;
      margin-bottom: 5px;
    }
    @include media-breakpoint-down(sm) {
      height: 32px;
      margin: 0;
    }
  }

  @include media-breakpoint-down(xl) {
    margin-left: 15px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 20px;
    line-height: 24px;
    margin-left: 15px;
  }
  @include media-breakpoint-down(md) {
    margin-right: 24px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1rem;
    line-height: 1.5em;
    margin: 0;
    padding: 0;
  }
}

.navbar-nav {
  align-self: flex-end;

  @include media-breakpoint-down(xl) {
    margin-left: 0;
    margin-right: 0;
  }
  @include media-breakpoint-down(lg) {
    margin-top: 0;
  }
  @include media-breakpoint-down(sm) {
    &.order-1 {
      width: 24px;
      height: 24px;
    }
  }
}

.settings {
  cursor: pointer;
  margin-top: -4px;
  padding: 18px;

  @include media-breakpoint-down(md) {
    margin-top: 0;
    padding: 0 0 0 10px;
  }
}

.burger {
  display: none;
  @media (max-width: 767.98px) {
    display: block;
  }
}

.nav-link {
  cursor: pointer;
  padding: 0.25rem 1rem !important;

  .navText {
    @include agbold();
    display: inline-block;
    color: $stratus;
    font-size: 1rem;
    line-height: 1.25em;
    transition: 0.2s all linear;
  }

  .navIcon {
    display: inline-block;
    margin: 0 10px;
    svg path {
      transition: 0.2s all linear;
    }

    @include media-breakpoint-down(md) {
      margin: 0 5px;
    }
  }

  &:hover {
    .navIcon {
      svg path {
        fill: $salesforce-blue-mid;
        transition: 0.2s all linear;
      }
    }
    .navText {
      color: $salesforce-blue-mid;
      transition: 0.2s all linear;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 0.25rem 0.75rem !important;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.nav-item {
  &.dropdown {
    .dropdown-toggle {
      @include agbold();
      color: $stratus;
      font-size: 1rem;
      line-height: 1.25em;
      transition: 0.2s color linear;
      padding-right: 40px !important;
      position: relative;

      &:after {
        content: url("../../assets/images/icon_arrow_down.svg");
        width: 14px;
        height: 14px;
        line-height: 0;
        border: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 16px;
        margin: auto;
      }

      @include media-breakpoint-down(md) {
        padding-right: 32px !important;

        &:after {
          right: 10px;
        }
      }
    }

    .dropdown-menu {
      width: 200%;
      background: transparent;
      border: none;
      box-shadow: none;

      > div {
        padding: 20px 10px;
        background: #fff;
        border: 1px solid rgba(24, 24, 24, 0.04);
        box-shadow: 0px 0px 2px rgba(24, 24, 24, 0.08),
          0px 2px 4px 1px rgba(24, 24, 24, 0.16);
        border-radius: 8px;
        column-count: 2;
      }

      .dropdown-item {
        @include agbold();
        font-size: 0.875rem;
        line-height: 2.5rem;
        color: $stratus;
        margin: 0 20px;
        padding: 0;
        width: auto;

        &:hover,
        &:focus {
          color: $default-solid;
          background: none;
        }
      }

      @include media-breakpoint-down(lg) {
        padding: 0.125rem 0;
      }
    }

    &.one-col {
      .dropdown-menu {
        width: auto;

        > div {
          column-count: 1;
        }

        .dropdown-item {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &.show {
      .dropdown-toggle {
        color: $default-solid;
        background: $salesforce-blue-6;
        border-radius: 16px;

        &:after {
          content: url("../../assets/images/icon_arrow_up.svg");
          transform: rotate(0deg);
        }
      }
    }
  }
}

.nav-link,
.nav-item {
  & + .nav-link,
  & + .nav-item {
    margin-left: 0.25rem;

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }
}

.searchPanel {
  // margin-top: 24px;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-top: 0;
  }

  .inputIcon.search {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 5px;
    svg path {
      fill: $salesforce-blue-mid;
    }
  }

  .form-control {
    position: relative;
    width: 10px;
    padding-left: 60px;
    margin: 0 10px;
    border: 1px solid transparent;
    height: 36px;
    transition: all 300ms;
    font-size: 30px;

    @include media-breakpoint-up(md) {
      height: 60px;
    }

    @include media-breakpoint-down(md) {
      font-size: 15px;
      height: 36px;
      padding-left: 40px;
    }

    &:focus {
      width: 280px;
      padding-left: 60px;
      margin: 0 10px;
      border: 1px solid #c1b9b4;
      border-color: $salesforce-blue-mid;
      box-shadow: none;
      @include media-breakpoint-down(md) {
        padding-left: 40px;
      }
    }
  }
}

.form-inline {
  margin-top: .25rem;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.menuIcon {
  padding-right: 10px;
  cursor: pointer;
  svg path {
    fill: #0d9dda;
  }
  @include media-breakpoint-down(md) {
    padding: 0;
    svg path {
      height: 15px;
      width: 15px;
      fill: #0d9dda;
    }
  }
  @include media-breakpoint-down(sm) {
    &.order-3 {
      line-height: 0;
      padding: 6px 4px 0 0;
    }
    &:not(.order-3) {
      position: absolute;
      top: 18px;
      right: 20px;
      padding: 0;
    }
  }
}

.mobileSearchContainer {
  background: $karl-the-fog;
  max-width: 100%;

  .mobileSearch {
    max-width: 1312px;
    margin: 0 auto;
    margin-top: -100px;
    padding: 10px 0px;
    height: 100px;
    transition: 0.15s all ease-out;

    @include media-breakpoint-down(md) {
      margin-top: -80px;
      height: 80px;
    }

    @include media-breakpoint-down(md) {
      margin-top: -80px;
      height: 80px;
    }

    &.active {
      display: block;
      margin-top: 0px;
      .searchPanel {
        opacity: 1;
        transition: 0.1s opacity linear;
        transition-delay: 0.2s;
      }
    }

    &.inactive {
      display: block;
      .searchPanel {
        opacity: 0;
        transform: translateX(-150vw);
        transition: 0.1s opacity linear;
        transition-delay: 0.2s;
      }
    }

    .searchPanel {
      .form-control {
        display: block;
        width: 100%;
        margin: 10px 0;
      }
      .inputIcon.search {
        top: 15px;
        left: 14px;
        svg {
          width: 30px;
          height: 30px;
        }

        @include media-breakpoint-down(md) {
          top: 6px;
          left: 8px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.settingsModal {
  .modal-content {
    border: none;
    border-radius: 8px;

    .modal-header {
      background: $salesforce-blue-mid;
      color: #fff;
      padding: 15px 30px;

      .modal-title {
        font-size: 22px;
      }

      .close {
        opacity: 0.9;
        font-size: 36px;
        color: #fff;
        text-shadow: none;
      }

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .modalSubhead {
      @include sansbold();
      margin-bottom: 10px;
      display: block;
    }

    .list-group-item {
      // auto set height based on lazy load of select components
      &.labelSet {
        min-height: 96px;
      }

      border-width: 0px;
    }

    .modal-footer {
      border-top: none;
      padding: 0 30px 20px 30px;

      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
.timezone-picker {
  width: 100%;
}

.navContainer {
  padding: 0 30px;
}

.navDivider {
  margin-right: 20px;
  margin-left: 10px;
  width: 1px;
  height: 26px;
  background-color: #9a8f87;

  @include media-breakpoint-down(md) {
    margin-right: 10px;
  }
}

.loginBtn {
  cursor: pointer;

  .loginBtnText {
    margin-right: 10px;
    font-size: 13px;
    @include sansbold();
  }

  @include media-breakpoint-down(md) {
    img {
      width: 30px !important;
      height: 30px !important;
    }
  }
}

.bm-burger-button {
  position: absolute;
  top: 17px;
  left: 16px;
  width: 22px;
  height: 22px;
  outline: none;
  button {
    outline: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $stratus;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $stratus;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: 22px;
  height: 22px;
  top: 17px !important;
  left: 16px;
  z-index: 1;

  button {
    outline: none;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  width: 22px !important;
  height: 22px !important;
}

.bm-menu-wrap {
  top: 0;
  left: 0;
  position: fixed;
  height: auto !important;
  max-height: 100vh;

  &.open {
    .bm-menu {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    }
  }
}

/* General sidebar styles */
.bm-menu {
  background: #e5e5e5;
  border-radius: 0px 0px 8px 8px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  height: auto !important;
  max-height: 100vh;
  overflow-y: scroll;
  background-color: white;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: transparent !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.menuHeader {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  height: 92px;
  background-color: white;
  padding: 0.75rem 1rem;
  outline: none;
  text-align: center;
  line-height: 0;
  border-bottom: 1px solid $salesforce-gray-light;
}

.menuTitle {
  width: 100%;
  text-align: left;
  display: inline-block;
  margin-top: 12px;
}

.menuContainer {
  outline: none;
  margin: 112px 12px 16px 20px;
  border-bottom: 1px solid $salesforce-gray-light;

  .nav-link {
    display: block;

    &:hover,
    &:focus {
      color: $stratus;
    }
  }

  .dropdown.one-col {
    padding: 0 0 12px 0;
    margin-bottom: 1rem;
    z-index: 0;

    .dropdown-menu {
      width: 100%;
      padding: 0;
      position: relative;

      > div {
        border: none;
        box-shadow: none;
        padding: 30px 0 0 0;
      }

      .dropdown-item {
        font-size: 1rem;
        line-height: 1rem;
        padding: 0 0 8px 0;
        margin: 0 0 16px 36px;
        width: auto;
      }
    }

    .dropdown-toggle {
      color: $stratus;
      line-height: 1rem;
      border-radius: 0;
      background: none;
      padding: 0 !important;
    }

    &.show {
      .dropdown-toggle {
        &:after {
          content: url("../../assets/images/icon_arrow_down.svg");
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.userContainer {
  outline: none;
  @include media-breakpoint-down(sm) {
    margin: 16px 12px 16px 20px;
  }

  border-bottom: 1px solid $salesforce-gray-light;

  .menuUserNav {
    display: flex;
    align-items: center;

    .menuUserIcon {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .menuUserText {
      line-height: 17px;
      max-width: none;
      overflow: auto;
      text-overflow: unset;
      white-space: normal;

      &--sub {
        font-size: 9px;
        line-height: 12px;
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.menuItem {
  display: flex !important;
  align-items: center;
  cursor: pointer;
  display: block;
  @include agbold();
  font-size: 1rem;
  line-height: 1em;
  color: $stratus;
  padding: 0 0 12px 0;
  margin-bottom: 1rem;
  background: none;
  outline: none;
  box-shadow: none;

  svg {
    height: 13px;
    margin-left: 10px;

    path {
      fill: $salesforce-neutral-2;
    }
  }

  &:hover {
    background: none;
  }
}

.menuUserNav {
  cursor: pointer;
  .menuUserText {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 13px;
    @include sansbold();
    &--firstname {
      @include media-breakpoint-down(md) {
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
        overflow: hidden;
        max-width: 100px;
        display: inline-block;
      }
    }
    &--sub {
      display: block;
      color: $night;
      font-size: 11px;
      text-transform: uppercase;
      @include sansreg();
    }

    @include media-breakpoint-down(xxl) {
      max-width: 275px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @include media-breakpoint-down(xl) {
      max-width: 140px;
    }
    @include media-breakpoint-down(lg) {
      max-width: 160px;
    }
  }
}

.menuUserIcon {
  outline: none;
  width: 42px;
  height: 42px;
  border-radius: 42px;
  background-color: $salesforce-blue-light;
  font-size: 26px;
  line-height: 36px;
  display: inline-block;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  color: #f5f2f2;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
}

.menuFlyoutItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #0b5cab;
  padding: 20px;
  background: #ffffff;
  outline: none;
  box-shadow: inset 0px -1px 0px #e3ddd7, inset 0px 1px 0px #e3ddd7;
  &:hover {
    background: #e3ddd7;
  }
}

.userSquare {
  position: absolute;
  background: #f5f2f2;
  width: 22.94px;
  height: 22.94px;
  margin-top: -10px;
  transform: rotate(45deg);
  z-index: -1;
  left: 210px;
}

.menuUserFlyout {
  position: absolute;
  padding: 5px;
  top: 60px;
  right: -36px;
  min-width: 280px;
  background: #f5f2f2;
}
