@import "../../styles/shared/_variables.scss";

.favorited-events_container {
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 15px;
  min-height: 520px;
  background-image: url("../../assets/images/BKGD-PANEL.png");
  background-image: -webkit-image-set(
    url("../../assets/images/BKGD-PANEL.png") 1x,
    url("../../assets/images/BKGD-PANEL@2x.png") 2x
  );
  background-repeat: no-repeat;
  background-color: #eaf5fe;
  background-size: contain;
  position: relative;
  overflow: hidden;

  .favorited-events_body {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      padding-left: 30px;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 5px;
    }
  }

  .favorited-events_header {
    min-height: 275px;

    h2 {
      max-width: 300px;
      padding: 40px 50px;

      @include media-breakpoint-down(lg) {
        padding: 40px;
        max-width: none;
      }
      @include media-breakpoint-down(md) {
        padding: 20% 30px 0;
        margin-bottom: 3rem;
      }
      @include media-breakpoint-down(sm) {
        padding-top: 25%;
      }
    }

    @include media-breakpoint-down(lg) {
      min-height: 225px;
    }

    @include media-breakpoint-down(md) {
      min-height: 0;
    }
  }

  .favorited-events_load-more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    transition: all 1s;
    visibility: visible;
    opacity: 1;
  }

  .favorited-events_load-more.hide {
    opacity: 0;
    visibility: hidden;
    margin-bottom: 0;
  }
}

.favorited-event_wrapper {
  margin-bottom: 26px;
  padding: 10px;
  .favorited-event_type {
    @include sansbold();
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
    display: block;
    color: $cirrus;
  }
  .favorited-event_title {
    color: #032e61;
    font-size: 17px;
    @include agbold();
    padding-bottom: 5px;
  }
  .favorited-event_time {
    color: #59575c;
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
    padding-bottom: 5px;
    text-transform: uppercase;
  }
}

.no-favorited-event_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 380px;
  margin: 0 auto;

  .no-favorited-event_title {
    padding-bottom: 5px;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    color: #0176d3;
  }

  .no-favorited-event_description {
    padding-top: 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #59575c;
  }

  .no-favorited-event_divider {
    height: 1px;
    width: 100%;
    background-color: #cfe9fe;
  }
}
