@import "../../styles/shared/_variables.scss";

.productTileWrapper {
    padding: 20px;
    margin: 8px;
    width: 200px;
    height: 64px;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,.25);
    display: flex;
    justify-content: left;
    align-items: center;

    @include media-breakpoint-down(sm) {
        width: 100%;
        }

    .productTileImage {
        width: auto;
        height: 34px;
        padding: 2px;
        margin: 8px;
    }

    .productTileContent {
        @include agbold();
        font-size: 16px;
        line-height: 18px;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .productSubtitle{
        @include sansreg();
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        color: #4E4E4E;
    }

    .image {
        max-width: 100%;
        max-height: 100%;
    }
}