@import "../../styles/shared/_variables.scss";

/*.heroRow {
  h1 {
    @include agbold();
    font-size: 40px;
    margin: 20px 0;
    color: $stratus;
  }
  .jumbotron {
    padding: 50px 0;
    p {
      @include media-breakpoint-up(lg) {
        max-width: 50%;
      }
    }
  }
}*/

.userPremierStatus {
  color: $cloud;
  text-transform: uppercase;
}

.edit-profile_link {
  text-decoration: underline !important;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #ded6cf;
}
