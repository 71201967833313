@import "../../styles/shared/_variables.scss";

.eventDetailWrapper {
  min-height: 100vh;

  .vidyard-player-container.playlist-open {
    width: 100% !important;
  }
}
.detailHeaderRow {
  h1 {
    @include agbold();
    color: $stratus;
    padding: 10px 0;
    font-size: 1.6rem;
    color: $stratus;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      font-size: 3rem;
      padding: 12px 0;
    }
  }
  .jumbotron {
    padding: 10px 0 50px;

    .detailBreadcrumb {
      @include sansbold();

      display: inline-block;
      text-transform: uppercase;
      color: $stratus;
      font-size: 12px;
      letter-spacing: 1.5px;
      line-height: 15px;
      margin-bottom: 20px;
      padding: 6px 0;

      span {
        position: relative;

        /*&:before {
          content: "<";
          position: absolute;
          right: calc(100% + 3px);
          top: -1px;
          transition: all 0.3s ease-in-out;
        }*/
      }

      &:hover {
        color: lighten($stratus, 15%);

        span {
          &:before {
            transform: translateX(-3px) scale(1.1);
          }
        }
      }
    }

    .detailEyebrow {
      display: block;
      @include sansbold();
      text-transform: uppercase;
      color: $stratus;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 1.5px;

      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }

    .detailSubhead {
      color: $stratus;
      @include sansbold();
      font-size: 15px;
      line-height: 24px;
      letter-spacing: normal;

      .like-container {
        margin-top: 10px;
      }

      &.headerOverview {
        margin-top: 20px;
      }
    }
    p {
      @include media-breakpoint-up(lg) {
        max-width: 50%;
      }
    }
  }
}

.detailSection {
  margin-bottom: 45px;
  h2 {
    font-size: 20px;
    @include sansbold();
  }
  h3 {
    font-size: 20px;
    @include sansbold();
  }
}

.eventDetailCard.card {
  margin-bottom: 40px;
  border: 0px;
  border-radius: 15px;

  .card-header {
    padding: 0;
    height: 140px;
    position: relative;
    background: $stratus;
    color: #fff;
    border: 0;
    border-radius: 15px 15px 0 0;

    &.premierCardDetail {
      background: $night;

      &.guest,
      &.standard {
        padding: 40px 60px;
        color: #fff;
        height: auto;
        a {
          color: $salesforce-blue-mid;
        }
      }

      &.standard {
        border-radius: 15px 15px 15px 15px;
      }

      .groupHeader {
        color: #fff;
        padding-top: 0px;
      }
    }

    .like-container {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
      z-index: 100;
      cursor: pointer;
    }

    .dateCallout {
      position: absolute;
      top: 20px;
      left: 20px;
      background: #fff;
      color: $stratus;
      width: 100px;
      height: 100px;
      padding: 8px 0;
      text-align: center;
      border-radius: 12px;

      &--month {
        @include sansbold();
        display: block;
        text-align: center;
        font-size: 14px;
        text-transform: uppercase;
      }

      &--day {
        @include agbold();
        display: block;
        text-align: center;
        font-size: 58px;
        line-height: 58px;
      }

      .dateSup {
        display: inline-block;
        font-size: 32px;
        padding-bottom: 18px;
        vertical-align: middle;
        line-height: 0;
      }
    }

    .eventHeaderSub {
      position: absolute;
      top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 120px;
      padding: 5px 20px;
      height: 100px;
      font-size: 18px;
      vertical-align: middle;

      &--item {
        @include sansbold();
        font-size: 15px;
        display: block;

        &.premierHeaderSub {
          color: $cloud;
          text-transform: uppercase;
        }
      }
    }
  }
  .card-body {
    background: $karl-the-fog;
    border-radius: 0px 0px 15px 15px;
    min-height: 170px;
    text-align: center;
    .btn {
      margin: 30px 0;
    }
    .eventStatusInline {
      margin: 30px auto;
      width: 95%;
      .statusHeader {
        display: inline-block;
        @include sansbold();
        color: $stratus;
        font-size: 20px;
      }
      .statusText {
        margin-top: 20px;
        color: $night;
      }
    }

    .registration-form-container {
      padding: 20px 20px 30px;
    }

    &.premierCardDetailContent {
      text-align: left;
      padding: 40px;

      &.guest {
        .signin-button {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.bucketParentHeader {
  border: 1px solid #c1b9b4;
  border-width: 1px 0 0 0;
  padding: 20px 0;

  .groupHeader {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 30px;
  }
}

.parentDateSpan {
  font-size: 20px;
  color: $cirrus;
  @include agbold();

  @include media-breakpoint-down(lg) {
    font-size: 18px;
  }

  &.listHeader {
    border: 1px solid #c1b9b4;
    margin-top: 20px;
    padding-top: 30px;
    border-width: 1px 0 0 0;
  }
}

.sessionTimeHeader {
  background: $karl-the-fog;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  margin: 20px 0;
  @include agbold();
  color: $cirrus;
}

.recurringEventWrapper {
  h3 {
    font-size: 15px;
    @include sansbold();
    text-transform: uppercase;
    color: black;
  }
  .btn-link {
    padding: 0px;
    width: auto;
    display: block;
    text-align: left;
    margin: 0 auto;
    @include sansbold();
    &:after {
      content: "+";
      @include sansreg();
      position: absolute;
      font-size: 26px;
      right: 15px;
      top: 8px;
    }

    &.inlineLink {
      &:after {
        content: none !important;
      }
    }
  }
}

.eventPremierBanner {
  background: $night;
  color: #fff;
  min-height: 120px;

  @include media-breakpoint-down(sm) {
    min-height: 80px;
  }

  .bannerMessage {
    text-align: center;
    height: 120px;

    @include media-breakpoint-down(sm) {
      background-size: 120px;
      height: 80px;
    }

    background-image: url("../../assets/images/event-detail-premier-astro.png");
    background-image: -webkit-image-set(
      url("../../assets/images/event-detail-premier-astro.png") 1x,
      url("../../assets/images/event-detail-premier-astro@2x.png") 2x
    );
    background-repeat: no-repeat;
  }

  .groupHeader {
    color: #fff;
    line-height: 80px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.premier-event {
  .detailHeaderRow .jumbotron {
    .detailBreadcrumb {
      color: #fff;

      &:hover {
        color: darken(#fff, 15%);
      }
    }
  }
}
