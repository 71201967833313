@import "../../../styles/shared/_variables.scss";

.coveo--facet {
  .filterCard.card {
    .card-header {
      padding: 18px 0;
    }
  }

  .card-body {
    max-height: 340px;
    overflow-y: scroll;
  }

  &.date-facet {
    .card-body {
      max-height: none;
      overflow-y: unset;

      > div {
        &:first-child {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  &:first-child {
    margin-top: 7px;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }
}
