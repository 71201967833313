@import "../../styles/shared/_variables.scss";

.expert-coaching-section {
  background-image: url("../../assets/images/hero-banners/expert-coaching-topics.png");
  background-image: -webkit-image-set(
    url("../../assets/images/hero-banners/expert-coaching-topics.png") 1x,
    url("../../assets/images/hero-banners/expert-coaching-topics@2x.png") 2x
  );
  background-repeat: no-repeat;
  background-color: $karl-the-fog;
  background-size: contain;
  margin: 0;
  padding: 30px;
  border-radius: 15px;

  &--header {
    min-height: 290px;

    .groupHeader {
      padding: 20px;
      max-width: 300px;

      @include media-breakpoint-down(md) {
        padding: 10px 0;
        max-width: 200px;
      }

      @include media-breakpoint-down(sm) {
        padding: 150px 0 0;
        max-width: none;
      }

      @include media-breakpoint-down(xs) {
        padding: 125px 0 0;
      }

      @media screen and (max-width: 550px) {
        padding: 100px 0 0;
      }

      @media screen and (max-width: 450px) {
        padding: 75px 0 0;
      }
    }

    @include media-breakpoint-down(lg) {
      min-height: 225px;
    }

    @include media-breakpoint-down(md) {
      min-height: 150px;
    }

    @include media-breakpoint-down(sm) {
      min-height: 0;
    }
  }

  h4 {
    @include sansbold();
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 1px;
    color: $cirrus;
    text-transform: uppercase;
    margin: 30px 0 20px;
  }

  .topics {
    margin: 0;
    border-bottom: 1px solid #ded6cf;
    padding-bottom: 30px;

    a {
      @include agbold();
      font-size: 17px;
      line-height: 25px;
      color: $stratus;
      //margin-bottom: 10px;
      border-bottom: 10px solid transparent;
      padding-right: 25px;
      display: block;
      break-inside: avoid-column;
    }

    &.small {
      column-count: 1;
    }
    &.medium {
      column-count: 3;
      // column-fill: auto;
      // height: 126px;

      @include media-breakpoint-down(md) {
        // height: auto;
        column-count: 2;
      }

      @include media-breakpoint-down(sm) {
        column-count: 1;
      }
    }
    &.tall {
      column-count: 3;
      // column-fill: auto;

      @include media-breakpoint-down(md) {
        column-count: 2;
      }

      @include media-breakpoint-down(sm) {
        column-count: 1;
      }
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.discoverExpertCoaching {
  text-align: center;

  .groupHeader {
    text-align: center;
  }

  .eccDescription {
    text-align: center;
    color: #181818;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 3rem;
  }
}

.groupHeader {
  font-size: 38px;
}

