@import "../../../styles/shared/_variables.scss";

.coveo--filter-tabs {
    border-color: $earth;

    .nav-link {
        @include sansbold();
        color: $black-ice;
        font-size: 1rem;
        line-height: 21px;
        border-width: 0;
        padding: 0 1.5rem 1.25rem !important;
        display: block;
        position: relative;

        &:after {
            content: '';
            width: calc(100% - 3rem);
            height: 4px;
            background-color: transparent;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        &:first-child {
            padding-left: 0 !important;

            &:after {
                width: calc(100% - 1.5rem);
                right: auto;                
            }

            @include media-breakpoint-down(sm) {
                padding-left: 30px !important;

                &:after {
                    width: calc(100% - 3rem);   
                    right: 0;             
                }
            }
        }

        &.active {
            color: $cirrus;
            background-color: transparent;
            border-radius: 0;

            &, &:hover, &:focus {
                &:after {
                    background-color: $cirrus;
                    display: block;
                }
            }
        }

        &:hover {
            &:after {
                background-color: $black-ice;
            }

            @include media-breakpoint-down(sm) {
                &:after {
                    display: none;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            flex-shrink: 0;
        }
    }

    .nav-item {
        &.show {
            .nav-link {
                color: $cirrus;
            }
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        margin-left: -30px;
        margin-right: -30px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
    }
}