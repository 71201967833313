
@import "../../styles/shared/_variables.scss";

.eccBanner {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  margin-top: -1px;

  margin-bottom: 2.8vw;

  @include media-breakpoint-up(sm) {
    min-height: var(--minHeight, 379px);
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 40px;
  }

  .eccDescription {
    color: #181818;
    font-size: 24px;
    line-height: 28px;

    @include media-breakpoint-down(sm){
      font-size: 16px;
      line-height: 20px;
    }
  }

  .maskedBg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: 284px;
    max-width: 2064px;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    // background-color: #8cd3f8;
    // background-color: var(--heroColor, #{$cloud});
    display: flex;
    // align-items: flex-end;
    justify-content: flex-end;

    @media (min-width: 2064px) {
      max-width: none;
    }

    @include media-breakpoint-down(md){
      left: 25%;
      display: none;
    }

    .mask {
      width: 100%;
      height: 100%;
      background-image: url(../../assets/images/da-swoosh.svg);
      background-position: bottom center;
      background-repeat: no-repeat;
      z-index: 6;
      position: absolute;
      bottom: 0;
      left: 0;

      background-size: 522px auto;

      @media (min-width: 522px) {
        background-size: 800px auto;
      }

      @include media-breakpoint-up(md) {
        background-size: auto;
      }

      @media (min-width: 2064px) {
        background-size: cover;
      }
    }

    img {
      position: relative;
      z-index: 1;

      &.image {
        opacity: 0;
        transition: opacity 0.85s cubic-bezier(0.72, 0, 0.28, 1);
      }
      &.image-loaded {
        opacity: 1;
      }

      @include media-breakpoint-down(md) {
        position: absolute;
        left: 50vw;
      }

      @include media-breakpoint-down(sm) {
        left: 40vw;
      }
    }

    &.plp img {
      margin-right: 10vw;

      @include media-breakpoint-down(lg) {
        margin-right: 0vw;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    /*&.sales-cloud {
      background: $sales-solid;
    }

    &.marketing-cloud {
      background: $marketing-solid;
    }

    &.service-cloud {
      background: $service-solid;
    }*/
  }

  .heroSubLink {
    display: inline-block;
    margin-left: 20px;
    padding: 0;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }

  .videoContainer {
    height: 0;
    overflow: hidden;
    padding-top: 56.2%;
    background: $salesforce-blue-5;
    position: relative;

    @include media-breakpoint-down(sm) {
      margin-left: -30px;
      margin-right: -30px;
      padding-top: 67.5%;
    }

    .videoContainer--inside {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.85s cubic-bezier(0.72, 0, 0.28, 1);
    }

    .videoContainer--loader {
      position: absolute;
      z-index: 100;
      text-align: center;
      top: 50%;
      left: 50%;
      width: 200px;
      height: 150px;
      margin-left: -100px;
      margin-top: -75px;
      transition: opacity 0.3s cubic-bezier(0.72, 0, 0.28, 1);

      svg {
        @include media-breakpoint-down(sm) {
          width: 62px;
          height: 62px;
        }
      }

      .videoContainer--loaderLabel {
        color: $stratus;
        padding-top: 20px;
        @include agbold();
      }
    }

    &.loaded {
      .videoContainer--inside {
        opacity: 1;
      }
      .videoContainer--loader {
        opacity: 0;
      }
    }
  }

  .heroContainer {
    position: relative;
    z-index: 2;

    &.container{
      padding-left: 0;
      padding-right: 0;
    }

    .imageBanner{
      @include media-breakpoint-down(md) {
          top: 20%;
          position: relative;
          z-index: 2;
      }
    }

    .mobBanner{
      display: none;

      @include media-breakpoint-down(md) {
        display: flex;
        position: relative;
        bottom: 10%;
      }
    }

    h1 {
      @include agbold();
      font-size: 1.5rem;
      color: $stratus;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        font-size: 2.2rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 3rem;
      }
    }

    .hero-header-content {
      @include media-breakpoint-up(lg) {
        padding-left: 0px;
        padding-top: 20px;
      }
    }

    p {
      padding-top: 10px;
    }

    .btn {
      margin-top: 1.5rem;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        padding: 8px 16px;
      }
    }

    .jumbotron {
      background: transparent;

      @include media-breakpoint-down(md) {
        padding: 30px 30px 0 30px;
        margin: 0;
      }

      .mobButton{

        display: none;

        @include media-breakpoint-down(md) {
          width: 100%;
          font-size: 16px;
          display: block;
        }
      }
    }
  }

  &.bucket-parent {
    .maskedBg {
      background-color: $stratus;
      color: #fff;
    }
    .detailHeaderRow .hero-header-content h1,
    .detailHeaderRow .jumbotron .detailSubhead {
      color: #fff;
    }
  }

  
  &.premier-event,
  &.footer-banner-premier {
    .maskedBg {
      background-color: $cirrus;
      color: #fff;
    }
    h1,
    h2,
    h3,
    h4,
    p,
    .detailEyebrow,
    .detailSubhead,
    .edit-profile_link {
      color: #fff !important;
    }
    .btn-primary {
      color: $cirrus;
      background-color: #fff;
      border-color: #fff;
      &:hover,
      &:active {
        color: $cirrus;
        background-color: darken(#fff, 10%);
        border-color: darken(#fff, 10%);
      }
    }
  }

  &.layout-footer {
    .mask {
      transform: rotate(180deg);
    }
    text-align: center;
    margin-bottom: -40px;

    &.footer-banner-premier {
      .maskedBg {
        bottom: -40px;
        @include media-breakpoint-down(md) {
          bottom: -1px;
        }
      }
      .heroContainer {
        margin-bottom: -80px;
        @include media-breakpoint-down(md) {
          margin-bottom: 0px;
        }
      }
      img {
        position: absolute;
        z-index: 7;
        left: 10vw;
        bottom: 20px;

        @include media-breakpoint-down(xl) {
          left: 0vw;
        }

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}
