/*

  THIS FILE IS FOR CUSTOMIZING BOOTSTRAP VARIABLES

*/

// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Color system
// Options
// Spacing
// Body
// Links
// Paragraphs
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code

//
// Color system
//

$font-size-base: 15;

$white: #fff !default;
$gray-100: #fafafa !default; // CUSTOM GRAY
$gray-200: #edeced !default; // CUSTOM GRAY
$gray-300: #afb2be !default; // CUSTOM GRAY
$gray-400: #333333 !default; // CUSTOM GRAY
$black: #242323 !default; // CUSTOM BLACK

$grays: (
  100: $gray-100,
  // CUSTOM GRAY
  200: $gray-200,
  // CUSTOM GRAY
  300: $gray-300,
  // CUSTOM GRAY
  400: $gray-400,
  // CUSTOM GRAY
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// jumbotron
$jumbotron-padding: 20px;

// $grid-gutter-width: 60px;
$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  xxl: 1312px,
) !default;
