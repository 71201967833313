@import "../../styles/shared/_variables.scss";

.featureGroup,
.filterGroup {
  .groupLink {
    display: inline-block;
    margin-top: 30px;
    font-size: 15px;
  }
}

.filterGroup {
  border-top: 1px solid $earth;
  padding-top: 40px;

  &.featureCol {
    border-top-width: 0px;
  }

  &.bottomBorder {
    border-top-width: 0px;
    border-bottom: 1px solid $earth;
    padding-bottom: 30px;
  }

  &.noBorder {
    border: none;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 20px;
    .btn {
      width: 100%;
    }
  }

  a {
    @include sansbold();
  }
}
