@import "../../styles/shared/_variables.scss";

.filterBar {
  padding-right: 30px;
  //padding-bottom: 200px;

  &.colPanel {
    min-height: 0;
  }

  @include media-breakpoint-down(lg) {
    padding-right: 0px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
    background: $karl-the-fog;
  }

  .btn-link {
    padding: 0px;
    width: 100%;
    text-align: left;
    &:focus {
      box-shadow: none;
      text-decoration: none;
    }
    &.filterBarToggleBtn {
      @include media-breakpoint-down(md) {
        padding: 20px 0;
        width: auto;
      }
    }

    h4 {
      @include media-breakpoint-down(md) {
        margin-bottom: 0px;
      }
    }

    &.inlineLink {
      width: auto;
    }
  }

  .shareButton {
    margin: 10px 0;
    font-size: 14px;
    @include sansreg();
    width: 100%;
    max-width: 220px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .shareButtonInline {
    margin: 20px 0 0 0;
    font-size: 14px;
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
      width: auto;
      float: right;
    }
  }

  h4 {
    font-size: 20px;
    color: $night;
    position: relative;
    @include sansbold();

    @include media-breakpoint-down(md) {
      font-size: 18px;
      padding-left: 22px;
      color: $cirrus;

      &:after {
        content: "+";
        @include sansreg();
        position: absolute;
        font-size: 26px;
        line-height: 18px;
        left: 0px;
        top: 0px;
      }
    }
  }

  .collapsed {
    h4 {
      @include media-breakpoint-down(md) {
        &:after {
          content: "+";
        }
      }
    }
  }
  .expanded {
    h4 {
      @include media-breakpoint-down(md) {
        &:after {
          content: "\2013";
        }
      }
    }
  }
}

.filterItem.list-group-item {
  border-radius: 0px;
  border-width: 0 0 1px 0;
  background: #fff;

  @include media-breakpoint-down(md) {
    background: $karl-the-fog;
  }
}

.filterBarGroup.list-group-item {
  border-radius: 0px;
  padding: 0;
  border-width: 0 0 1px 0;
  background: #fff;

  @include media-breakpoint-down(md) {
    background: $karl-the-fog;
  }

  &.lastGroup {
    .card-header {
      @include media-breakpoint-down(md) {
        border-width: 0;
      }
    }
  }

  .filter-disabled .slds-form-element__label {
    color: #c9c7c5;
  }

  &.toggleGroup {
    padding: 10px 0;
    border-width: 0;
    position: relative;

    .filterToggleExcludePremier {
      width: 200px;
      margin-top: 10px;

      .slds-checkbox_toggle {
        flex-flow: row-reverse;
        justify-content: left;
        align-items: flex-start;
      }

      .slds-checkbox_faux_container {
        cursor: pointer;
        width: 50px;
        .slds-checkbox_on,
        .slds-checkbox_off {
          display: none !important;
        }
      }

      .slds-form-element__label {
        cursor: pointer;
        width: 150px;
        margin-left: 10px;
        font-size: 0.8125rem;
      }
    }

    .toggleInfoLabel {
      display: block;
      margin-right: 0;
      margin-left: auto;
      position: absolute;
      right: 10px;
      top: 24px;
      @include sansreg();
    }
  }
}

.filterCard.card {
  border: 0;
  background: #fff;
  overflow: visible !important;

  .btn-link {
    font-size: 15px;
    @include sansbold();
    &:after {
      content: "+";
      @include sansreg();
      position: absolute;
      font-size: 26px;
      right: 15px;
      top: 8px;
    }

    &.inlineLink {
      font-size: 13px;
      &:after {
        content: none !important;
      }
    }
  }

  .card-header {
    background: #fff;
    padding: 15px 0;
    color: $cirrus;
    @include media-breakpoint-down(md) {
      background: $karl-the-fog;
    }
  }

  .card-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  @include media-breakpoint-down(md) {
    background: $karl-the-fog;
  }
}

.filterBarGroup {
  .accordion {
    &.collapsed {
      .btn-link {
        &:after {
          content: "+";
        }
      }
    }
    &.expanded {
      .btn-link {
        &:after {
          content: "\2013";
          //right: 18px;
        }
      }
    }
  }
  a {
    @include sansbold();
    display: inline-block;
    margin-right: 28px;
    font-size: 13px;
  }
}

.filterContent {
  &--date {
    height: 400px;
  }
}

.DateInput {
  width: 108px !important;
  &_input {
    font-size: 14px !important;
  }
}

.dateSpanLinks {
  margin-top: 20px;
  padding-left: 8px;

  .inlineLink {
    margin: 0 8px;
  }

  @include media-breakpoint-down(lg) {
    padding-left: 0px;

    .inlineLink {
      margin: 0 4px;
    }
  }
}

.sticky-inner-wrapper {
  z-index: 1;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: $cirrus;
  border-color: $cirrus;
}

.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  border-color: $salesforce-blue-outline;
  background-color: $salesforce-blue-mid;
  color: #fff;
}

.DateInput_input__focused {
  border-color: $cirrus;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}
