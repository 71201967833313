@import "../../../styles/shared/_variables.scss";

.sf-input-container {
  text-align: left;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.sf-input-label {
  @include sansreg();
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: $night;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 12px;
  }
}

.sf-input-control {
  border-color: #979797;
  border-radius: 4px;
  @include sanslight();
  font-size: 20px;
  line-height: 24px;
  padding: 12px 15px;

  @media (max-width: 768px) {
    padding: 11px;
    font-size: 16px;
    line-height: 16px;
  }
  &:focus {
    border: 1px solid #00a1e0;
    box-shadow: 0px 0px 3px 1px #00a1e0;
  }
}

.sf-input-validation-message {
  margin-top: 2px;
  @include sansreg();
  font-size: 15px;
  color: #c23934;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #979797;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjczNTEgMTcuMDMwOEwxMi41OTcyIDAuNjYzNTM3QzEyLjA2NTkgLTAuMjIxMTc5IDEwLjkxNDkgLTAuMjIxMTc5IDEwLjM4MzcgMC42NjM1MzdMMC4yNDU3NjUgMTcuMDMwOEMtMC4zNzQwMTkgMTguMDQ4MiAwLjI0NTc2NSAxOS40NjM4IDEuMzUyNTIgMTkuNDYzOEgyMS42MjgzQzIyLjczNTEgMTkuNDYzOCAyMy4zOTkxIDE4LjA0ODIgMjIuNzM1MSAxNy4wMzA4Wk0xMS40OTA0IDE1LjkyNDlDMTAuNzM3OCAxNS45MjQ5IDEwLjE2MjMgMTUuMzQ5OCAxMC4xNjIzIDE0LjU5NzhDMTAuMTYyMyAxMy44NDU4IDEwLjczNzggMTMuMjcwNyAxMS40OTA0IDEzLjI3MDdDMTIuMjQzIDEzLjI3MDcgMTIuODE4NSAxMy44NDU4IDEyLjgxODUgMTQuNTk3OEMxMi44MTg1IDE1LjM0OTggMTIuMjQzIDE1LjkyNDkgMTEuNDkwNCAxNS45MjQ5Wk0xMi44MTg1IDExLjk0MzdDMTIuODE4NSAxMi4yMDkxIDEyLjY0MTQgMTIuMzg2IDEyLjM3NTggMTIuMzg2SDEwLjYwNUMxMC4zMzk0IDEyLjM4NiAxMC4xNjIzIDEyLjIwOTEgMTAuMTYyMyAxMS45NDM3VjYuMTkzMDFDMTAuMTYyMyA1LjkyNzYgMTAuMzM5NCA1Ljc1MDY1IDEwLjYwNSA1Ljc1MDY1SDEyLjM3NThDMTIuNjQxNCA1Ljc1MDY1IDEyLjgxODUgNS45Mjc2IDEyLjgxODUgNi4xOTMwMVYxMS45NDM3WiIgZmlsbD0iI0MyMzkzNCIvPgo8L3N2Zz4K");
  background-size: calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #979797;
  background-color: #eaf5fe;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyMyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguMTM4ODMgMTUuNzUyOEwwLjIxNjA3NSA3LjkzNTI1Qy0wLjA3MjAyNTEgNy42NTI2OSAtMC4wNzIwMjUxIDcuMTgxNzUgMC4yMTYwNzUgNi44OTkxOUwxLjI3MjQ0IDUuODYzMTNDMS41NjA1NCA1LjU4MDU3IDIuMDQwNzEgNS41ODA1NyAyLjMyODgxIDUuODYzMTNMOC4yODI4OCAxMS43NDk4QzguNDc0OTUgMTEuOTM4MiA4LjgxMTA2IDExLjkzODIgOS4wMDMxMyAxMS43NDk4TDIwLjY3MTIgMC4yMTE5MjFDMjAuOTU5MyAtMC4wNzA2NDAyIDIxLjQzOTUgLTAuMDcwNjQwMiAyMS43Mjc2IDAuMjExOTIxTDIyLjc4MzkgMS4yNDc5OEMyMy4wNzIgMS41MzA1NCAyMy4wNzIgMi4wMDE0NyAyMi43ODM5IDIuMjg0MDNMOS4xOTUyIDE1Ljc1MjhDOC45MDcxIDE2LjA4MjQgOC40MjY5MyAxNi4wODI0IDguMTM4ODMgMTUuNzUyOFoiIGZpbGw9IiMyNzg4MDYiLz4KPC9zdmc+Cg==");
  background-size: calc(0.75em + 0.375rem);
}
