@import "../abstracts/custom";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

$salesforce-blue-light: #e5f6fc;
$salesforce-blue-mid: #0d9dda;
$salesforce-blue-dark: #215ca0;
$salesforce-blue-5: #cfe9fe;
$salesforce-blue-6: #eaf5fe;

$salesforce-blue-outline: #0091ca;

$salesforce-gray-dark: #5c5c5c;
$salesforce-gray-light: #e5e5e5;

$salesforce-neutral-1: #9a8f87;
$salesforce-neutral-2: #c1b9b4;

$karl-the-fog: #f5f2f2;
$stratus: #032d60;
$night: #222222;
$black-ice: #59575c;
$cloud: #90d0fe;
$earth: #ded6cf;
$cirrus: #0b5cab;
$mist: #f5f2f2;

$earth-mid: #eeeae7;

// product colors
$sales-solid: #0b827c;
$marketing-solid: #825101;
$service-solid: #8a033e;
$commerce-solid: #396547;
$community-solid: #a86403;
$platform-solid: #321d71;
$industry-solid: #481a54;
$partners-solid: #e4a201;
$productivity-solid: #d83a00;

$default-solid: #0176d3;

// addtl colors
$light-yellow: #fdf1d4;

// util colors
$form-error: #c23934;

@mixin sansreg() {
  font-family: "salesforcesans-regular", sans-serif;
  font-weight: normal;
  font-style: normal;
}
@mixin sansbold() {
  font-family: "salesforcesans-bold", sans-serif;
  font-weight: normal;
  font-style: normal;
}
@mixin sanslight() {
  font-family: "salesforcesans-light", sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin agbold() {
  font-family: "avantegarde-bold", sans-serif;
  font-weight: normal;
  font-style: normal;
}
