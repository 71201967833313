@import "../../styles/shared/_variables.scss";

.loadingTextContainer {
  display: inline-block;
  margin-top: 30px;
}

.loadingTextInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.loadingTextText {
  color: $stratus;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 20px;
}

.loadingTextProxy {
  opacity: 0;
  position: relative !important;
  padding: 0 5px;
  margin-right: 30px;
  margin-left: 10px;
}

.loadingTextNext,
.loadingTextCurrent {
  @include sansreg();
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-left: 10px;
  position: absolute;
  left: 0;
  top: 0px;
  transition-property: transform, opacity;
  transition-duration: 1220ms, 720ms;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.loadingTextNext {
  
  &.animating-0 {
    transition-duration: 0ms, 0ms !important;
    transform: translateY(-100%);
    opacity: 0;
  }
  &.animating-1 {
    transition-duration: 0ms, 0ms !important;
    transform: translateY(-100%);
    opacity: 0;
  }
  &.animating-2 {
    transform: translateY(0);
    opacity: 1;
  }
}

.loadingTextCurrent {
  
  &.animating-0 {
    transition-duration: 0ms, 0ms !important;
    transform: translateY(0);
    opacity: 1;
  }
  &.animating-1 {
    transform: translateY(0);
    opacity: 1;
  }
  &.animating-2 {
    transform: translateY(100%);
    opacity: 0;
  }
}

.loadingTextDots {
  width: 20px;
  min-width: 20px;
  display: inline-flex;
  margin-left: 1px;
  letter-spacing: 1px;
}

.loadingTextSpinner,
.loadingTextSpinner:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.loadingTextSpinner {
  display: block;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinner 1.8s infinite linear;
  animation: spinner 1.8s infinite linear;

  svg {
    width: 100%;
    height: 100%;
    display: block;
    
    path {
      fill: $stratus;
    }
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
