@import "../../styles/shared/_variables.scss";

.loginPage {
  min-height: 470px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginRow {
  width: 100%;
  height: 100%;
  max-width: 980px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBgCol,
.loginBgContainer {
  min-height: 480px;
  width: 500px;
  min-width: 500px;
  position: relative;
  margin: 0 auto 0 4%;
  padding: 0;
}
.loginContentCol {
  width: 100%;
  max-width: 425px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBgImg,
.loginShadowImg,
.loginAstroImg {
  outline: 0 !important;
  user-select: none;
}

.loginBgImg {
  z-index: 1 !important;
}

.loginShadowImg {
  position: absolute !important;
  bottom: 85px;
  right: 166px;
  z-index: 2 !important;
}

.loginAstroImg {
  position: absolute !important;
  bottom: 155px;
  right: 167px;
  z-index: 3 !important;
}

.loginChildContainer {
  padding-bottom: 30px;
}

.loginFloatingAnim {  
  animation-name: loginFloatingAnim;
  animation-duration: 4.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes loginFloatingAnim {
  0% { transform: translate(0,  0px); }
  50% { transform: translate(0, 30px); }
  100% { transform: translate(0, -0px); }
}

.loginShadowAnim {  
  animation-name: loginShadowAnim;
  animation-duration: 4.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes loginShadowAnim {
  0% { transform: scale(1.05); opacity: 0.5; }
  50% { transform: scale(0.7); opacity: 1.0; }
  100% { transform: scale(1.05); opacity: 0.5; }
}

@include media-breakpoint-down(sm) {

  .loginBgContainer {
    transform: scale(0.75) translateY(-70px);
  }
}

@include media-breakpoint-down(lg) {

  .loginChildContainer,
  .loginChildContainer .jumbotron {
    padding-bottom: 0 !important;
  }
  
  .loginBgCol,
  .loginBgContainer {
    margin: 0 auto;
  }
}

@include media-breakpoint-between(lg, xl) {

  .loginBgCol,
  .loginBgContainer {
    margin-left: 2%;
  }
}
@include media-breakpoint-down(lg) {
  
  .loginContentCol {
    margin-bottom: 14px;   
  }
}

@include media-breakpoint-up(xl) {

  .loginRow {
    max-width: 1080px;
  }
  
  .loginBgCol,
  .loginBgContainer {
    margin-left: 8%;
  }
}
