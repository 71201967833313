@import "../../styles/shared/_variables.scss";

.pillPanel {
  .slds-pill {
    margin: 0 10px 10px 0;
    border-radius: 4px;
    border-width: 2px;
    border-color: $salesforce-gray-dark;

    &__action {
      @include sansbold();
      font-size: 14px;
      line-height: 20px;
      padding: 4px 28px 4px 12px;
      border-radius: 4px;
      border-width: 2px;
      border-color: $salesforce-gray-dark;
      background: transparent;
      color: $salesforce-gray-dark;
    }

    &:hover {
      background: $salesforce-gray-light;
    }

    &.invalidPill {
      .slds-pill__action {
        background: $form-error;
        border-color: $form-error;
      }
      .slds-pill__remove {
        &:hover {
          background-color: darken($form-error, 10%) !important;
          transition: 0.1s all linear;
          .slds-icon {
            fill: #fff;
            transition: 0.1s all linear;
          }
        }
      }
    }

    &.slds-pill_link .slds-pill__remove {
      right: 10px;
      background-color: transparent;
      border-radius: 20px;
      width: 18px;
      height: 18px;
      padding: 4px;

      .slds-icon {
        fill: $salesforce-gray-dark;
        transform: scale(1.25);
      }

      &:hover {
        .slds-icon {
          fill: $salesforce-gray-dark;
        }
      }
    }
  }
}
