@import "../../styles/shared/_variables.scss";

.eventCard.card {
  margin-bottom: 30px;
  height: 280px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: 0px;
  transition: 0.2s all linear;
  border-radius: 15px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
    height: auto;
    min-height: 240px;
  }

  &.trendingTopicCard {
    height: 320px;

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  &.onDemandCard {
    height: 340px;
  }

  &.withBtns {
    height: 320px;
  }

  &:hover {
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.2);
    transition: 0.2s all linear;
  }

  .card-header {
    padding: 15px 15px 0;
    position: relative;
    background: #fff;
    border: 0;
    border-radius: 15px;
    display: flex;

    .like-container {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
      z-index: 100;
      cursor: pointer;
    }

    .dateCallout,
    .labelCallout,
    .vidCallout {
      background: $karl-the-fog;
      color: $stratus;
      width: 90px;
      height: 90px;
      padding: 5px 0;
      text-align: center;
      border-radius: 10px;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      flex: 0 0 90px;
      float: left;

      &--month {
        @include sansbold();
        display: block;
        text-align: center;
        font-size: 12.6px;
        line-height: 1rem;
        text-transform: uppercase;
      }

      &--day {
        @include agbold();
        display: block;
        text-align: center;
        font-size: 43.2px;
        line-height: 52px;
        margin-top: -5px;
      }

      &--time {
        @include sansreg();
        display: block;
        text-align: center;
        font-size: 10px;
        line-height: 22px;
        text-transform: uppercase;
        background: $earth-mid;
        border-radius: 0 0 10px 10px;
      }

      .dateSup {
        display: inline-block;
        font-size: 28.8px;
        margin-top: -12px;
        vertical-align: middle;
        line-height: 32px;
      }
    }
    .labelCallout {
      padding: 0;
      justify-content: space-between;
      &--text {
        @include agbold();
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 54px;
        padding: 7px 0;
      }
    }
    .vidCallout {
      padding: 0;
      justify-content: space-between;
      &--icon {
        padding: 12px 0;

        svg {
          width: 44px;
          height: 44px;
        }
      }

      &--time {
        text-transform: lowercase;
      }
    }

    .eventHeaderSub {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      padding: 5px 15px;
      font-size: 16px;
      line-height: 0;
      // vertical-align: middle;

      &--item {
        @include sansbold();
        font-size: 12px;
        line-height: 22px;
        text-transform: uppercase;
        display: block;
        color: $cirrus;
        &.accentItem {
          color: $stratus;
          font-size: 14px;
        }
      }
    }

    .eyebrowLabel {
      @include sansbold();
      font-size: 14px;
      line-height: 18px;
      // display: inline-block;
      color: $default-solid;
      padding: 0;
      margin: 1px 0;

      &.marketing-cloud,
      &.advertising-studio,
      &.email-messaging-and-journeys,
      &.datorama,
      &.interaction-studio,
      &.pardot,
      &.social-studio {
        color: $marketing-solid;
        background: none;
      }

      &.salesforce-platform,
      &.appexchange,
      &.heroku,
      &.salesforce-customer-360 {
        color: $platform-solid;
        background: none;
      }

      &.commerce-cloud,
      &.b2b-commerce,
      &.b2c-commerce {
        color: $commerce-solid;
        background: none;
      }

      &.sales-cloud,
      &.essentials,
      &.lightning-experience,
      &.salesforce-anywhere,
      &.salesforce-maps {
        color: $sales-solid;
        background: none;
      }

      &.communications-cloud,
      &.consumer-goods-cloud,
      &.education-cloud,
      &.energy-and-utilities-cloud,
      &.financial-services-cloud,
      &.health-cloud,
      &.manufacturing-cloud,
      &.media-cloud,
      &.philanthropy-cloud,
      &.public-sector-solutions {
        color: $industry-solid;
        background: none;
      }

      &.service-cloud,
      &.field-service {
        color: $service-solid;
        background: none;
      }

      &.sub-tag {
        color: $black-ice;
        background: none;
      }

      // &:after {
      //   content: "\2022";
      //   @include sansbold();
      //   font-size: 11px;
      //   color: $black-ice;
      //   line-height: 14px;
      //   margin: 0 5px;
      // }

      &:last-child {
        &:after {
          content: none;
        }
      }

      @include media-breakpoint-down(sm) {
        margin: 2.5px 0;
      }
    }

    .eccmetadata {
      font-size: 12px;
      color:#000000;
      line-height: normal;
      font-family: salesforcesans-regular,sans-serif;
      margin-top: auto;
    }
  }

  &.eventMin {
    min-height: 120px;
    height: auto;

    .eventHeaderSub {
      &--item {
        text-transform: none;
        font-size: 14px;
        &.accentItem {
          @include agbold();
          font-size: 16px;

          @include media-breakpoint-down(md) {
            max-height: 60px;
            overflow: hidden;
            line-height: 18px;
          }
        }
      }
    }
  }

  .eventTagWrapperFooter {
    padding: 15px;
    margin-bottom: 28px;

    .eccEventType {
      color: #000000
    }

    .eccProduct {
      color: #825101;
    }
  }

  .card-body {
    padding: 1.25rem 15px;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;

    .complete-share-button {
      all: unset;
      border: 1px solid #eeeae7;
      border-radius: 7px;
      width: 100%;
      height: 35px;
      color: #215ca0;
      position: relative;
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-self: center;
      cursor: pointer;
    }
    .react-add-to-calendar {
      all: unset;
      display: inline;
      width: 100%;
      position: relative;
      z-index: 2;
      .react-add-to-calendar__button {
        width: 100%;
        height: 35px;
        user-select: none;
      }
      .react-add-to-calendar__dropdown {
        background-color: white;
        box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.15);
        border-radius: 0 0 8px 8px;
        position: absolute;
        top: 35px;
        min-width: 245px;
        left: 6px;
        max-width: 100%;
        z-index: -1;
        ul {
          padding-top: 10px;
          overflow: hidden;
          user-select: none;
          li {
            text-align: initial;
            color: #222222;
            padding: 15px;
            user-select: none;
            a {
              user-select: none;
              display: block;
              color: #222222;
              &:hover {
                color: #222222;
              }
            }
            &:hover {
              cursor: pointer;
              color: #222222;
              width: 100%;
              background-color: #f4f4f4;
            }
          }
        }
      }
    }
    .eventTagWrapper {
      font-size: 0;
      line-height: 0;
      position: absolute;
      max-height: 43px;
      overflow: hidden;
      bottom: 14px;
      padding-right: 1.25rem;
    }
    .eyebrowLabel {
      @include agbold();
      font-size: 10px;
      line-height: 10px;
      border-radius: 2px;
      display: inline-block;
      padding: 5px 8px 4px 8px;
      margin: 0 5px 5px 0;
      color: #fff;
      background: $default-solid;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &.marketing-cloud,
      &.advertising-studio,
      &.email-messaging-and-journeys,
      &.datorama,
      &.interaction-studio,
      &.pardot,
      &.social-studio {
        background: $marketing-solid;
      }

      &.salesforce-platform,
      &.appexchange,
      &.heroku,
      &.salesforce-customer-360 {
        background: $platform-solid;
      }

      &.commerce-cloud,
      &.b2b-commerce,
      &.b2c-commerce {
        background: $commerce-solid;
      }

      &.sales-cloud,
      &.essentials,
      &.lightning-experience,
      &.salesforce-anywhere,
      &.salesforce-maps {
        background: $sales-solid;
      }

      &.communications-cloud,
      &.consumer-goods-cloud,
      &.education-cloud,
      &.energy-and-utilities-cloud,
      &.financial-services-cloud,
      &.health-cloud,
      &.manufacturing-cloud,
      &.media-cloud,
      &.philanthropy-cloud,
      &.public-sector-solutions {
        background: $industry-solid;
      }

      &.service-cloud,
      &.field-service {
        background: $service-solid;
      }

      &.sub-tag {
        background: $earth-mid;
        color: $black-ice;
      }

      &.tier-tag {
        @include agbold();
        font-size: 10px;
        line-height: 17.5px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: #fff;
        background: $night;
        border-radius: 10px;
        padding: 0 8px;
        margin: 0;
        position: absolute;
        bottom: 15px;
      }

      &.tier-tag_right {
        right: 15px;
      }

      &.tier-tag_left {
        left: 15px;
      }
    }
    .eventTitle {
      display: block;
      margin-top: 0;
      font-size: 22px;
      line-height: 28px;
      color: $stratus;
      @include agbold();
    }

    // reduce height available when buttons are available
    &.withBtns {
      .eventTitle {
        max-height: 80px;
        overflow: hidden;
      }
    }

    .eventOverview {
      font-size: 15px;
      margin-top: 10px;
      color: $black-ice;
    }

    .expertCoachingOverview {
      font-size: 14px;
      margin-top: 10px;
      color: #181818;
      font-family: salesforcesans-regular,sans-serif;
      word-break: break-word;
    }
  }

  .slds-badge {
    margin-left: 0px !important;
    margin-right: 5px !important;
  }

  .badgeSet {
    //background: #eee;
    position: absolute;
    bottom: 14px;
    font-size: 11px;
    color: #c2c2c2;
    text-transform: uppercase;
    span {
      display: inline-block;
      padding: 0 2px;
    }
  }
}

.skeletonCard {
  margin-bottom: 36px;
}

.portraitVideoSVG {
  pointer-events: none;
}
