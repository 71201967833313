@import "../../styles/shared/_variables.scss";

.select-container {
  text-align: left;
  margin-bottom: 13px;
  font-size: 20px;
  font-family: "salesforcesans-light", sans-serif;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.select-dropdown {
  padding: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-dropdown-icon_container {
  margin-right: 14px;
  margin-top: -6px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }

  svg {
    width: calc(0.75em + 0.375rem);
  }
}

.select-title {
  @include sansreg();
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: $night;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    font-size: 11px;
    line-height: 12px;
  }
}

.select-error {
  @include sansreg();
  padding-top: 5px;
  font-size: 15px;
  line-height: 24px;
  color: #c23934;
}

.select {
  @include sanslight();
  font-size: 20px;
  line-height: 24px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 16px;
  }
  ::selection {
    background: #d8edff;
    text-shadow: none;
    color: #080707;
  }
}
