@import "../../../styles/shared/_variables.scss";

.sf-checkbox-control {
  @include sansreg();
  font-size: 15px;
  line-height: 24px;

  @media (max-width:768px) {
    font-size: 10px;
    line-height: 16px;
  }

  input {
    height: 20px;
    width: 20px;
  }

  label {
    padding-left: 12px;
  }
}

.form-text {
  margin-left: 10px;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: $night;
}