@import "../../../styles/shared/_variables.scss";

.coveo--result {
  .portraitCallout,
  .dateCallout,
  .labelCallout,
  .vidCallout {
    background: $karl-the-fog;
    color: $stratus;
    width: 100px;
    height: 100px;
    padding: 5px 0;
    margin-right: 1.5rem;
    text-align: center;
    border-radius: 12px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    flex: 0 0 100px;
    float: left;
    position: absolute;

    &--month {
      @include sansbold();
      display: block;
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
    }

    &--day {
      @include agbold();
      display: block;
      text-align: center;
      font-size: 48px;
      line-height: 58px;
      margin: -4px 0 0;
    }

    &--time {
      @include sansreg();
      display: block;
      text-align: center;
      font-size: 11px;
      line-height: 25px;
      text-transform: uppercase;
      background: $earth-mid;
      border-radius: 0 0 12px 12px;
    }

    .dateSup {
      display: inline-block;
      font-size: 22px;
      padding-bottom: 10px;
      vertical-align: middle;
      line-height: 0;
    }

    @include media-breakpoint-down(sm) {
      margin-right: 18px;
    }
  }
  .labelCallout {
    padding: 0;
    justify-content: space-between;

    &--text {
      @include agbold();
      display: block;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 56px;
      padding: 10px 0;
    }
  }
  .vidCallout {
    padding: 0;
    justify-content: space-between;

    &--icon {
      padding: 12px 0;
    }

    &--time {
      text-transform: lowercase;
    }
  }
  .portraitCallout {
    padding: 0;
    background-color: $salesforce-blue-mid;

    span {
      width: 100px;

      svg {
        width: 100px;
        height: 100px;
      }
    }
  }

  &.eventCard {
    min-height: 148px;
    height: auto;
    margin-bottom: 30px;

    .card-header {
      height: auto;

      .eventHeaderSub {
        height: auto;
        position: relative;
        top: 0;
        left: 0;
        padding: 1rem 1.5rem 0;

        .eventHeaderSub--item {
          text-transform: uppercase;
        }
      }
    }
    .card-body {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;

      .eventTagWrapper {
        bottom: 0;
        position: relative;
        padding: 0 0 0 124px;

        @include media-breakpoint-down(sm) {
          display: flex;
          min-height: 100px;
          max-height: none;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          padding: 0 0 0 118px;

          &:first-child {
            min-height: auto;
          }
        }
      }

      .cardBodyInner {
        padding: 0 0 0 124px;

        @include media-breakpoint-down(sm) {
          margin-top: 1.25rem;
          padding: 0;
        }
      }

      .eventTitle {
        @include agbold();
        font-size: 22px;
        line-height: 28px;

        @include media-breakpoint-down(md) {
          max-height: none;
        }
      }

      .eventOverview {
        color: $stratus;
        font-size: 14px;
        line-height: 21px;
        margin-top: 8px;
      }

      .eyebrowLabel {
        @include sansbold();
        font-size: 11px;
        line-height: 14px;
        color: $default-solid;
        padding: 0;
        margin: 0 0 10px 0;
        background: none;

        &.marketing-cloud,
        &.advertising-studio,
        &.email-messaging-and-journeys,
        &.datorama,
        &.interaction-studio,
        &.pardot,
        &.social-studio {
          color: $marketing-solid;
          background: none;
        }

        &.salesforce-platform,
        &.appexchange,
        &.heroku,
        &.salesforce-customer-360 {
          color: $platform-solid;
          background: none;
        }

        &.commerce-cloud,
        &.b2b-commerce,
        &.b2c-commerce {
          color: $commerce-solid;
          background: none;
        }

        &.sales-cloud,
        &.essentials,
        &.lightning-experience,
        &.salesforce-anywhere,
        &.salesforce-maps {
          color: $sales-solid;
          background: none;
        }

        &.communications-cloud,
        &.consumer-goods-cloud,
        &.education-cloud,
        &.energy-and-utilities-cloud,
        &.financial-services-cloud,
        &.health-cloud,
        &.manufacturing-cloud,
        &.media-cloud,
        &.philanthropy-cloud,
        &.public-sector-solutions {
          color: $industry-solid;
          background: none;
        }

        &.service-cloud,
        &.field-service {
          color: $service-solid;
          background: none;
        }

        &.sub-tag {
          color: $black-ice;
          background: none;
        }

        &.tier-tag {
          @include agbold();
          font-size: 10px;
          line-height: 17.5px;
          letter-spacing: 0.5px;
          color: #fff;
          background: $night;
          border-radius: 10px;
          padding: 0 8px;
          margin: 0;
          position: absolute;
          bottom: 15px;
        }

        &.tier-tag_right {
          right: 15px;
        }
  
        &.tier-tag_left {
          left: 15px;
          // position: unset;
          align-self: flex-start;
          margin-top: 10px;
        }

        &:after {
          content: "\2022";
          @include sansbold();
          font-size: 11px;
          line-height: 14px;
          color: $salesforce-neutral-1;
          margin: 0 0.5rem;
        }

        &:last-child {
          &:after {
            content: none;
          }
        }

        @include media-breakpoint-down(sm) {
          margin: 2.5px 0;
        }
      }

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    &.portraitCard {
      .card-body {
        display: flex;

        .eventTagWrapper {
          padding: 0;
        }
        .cardBodyInner {
          padding: 0;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      min-height: 267px;
    }
  }

  &.overview, &.adminfundamentals {
    height: 340px;
    
    .card-body {
      padding: 15px 15px;
      justify-content: start;
      flex-wrap: nowrap;

      .eventTagWrapper {
        bottom: 0;
        position: relative;
        display: flex;
        padding: 0 0 0 124px;
        flex-direction: column;
        max-height: 82px;
        overflow: visible;

        @include media-breakpoint-down(sm) {
          display: flex;
          min-height: 100px;
          max-height: none;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          padding: 0 0 0 118px;

          &:first-child {
            min-height: auto;
          }
        }
      }

      .eyebrowLabel {
        margin: 0;
        font-size: 14px;
        line-height: 18px;

        &:after {
          content: none;
        }

        &:last-child {
          &:after {
            content: none;
          }
        }
      }

      .eccmetadata {
        font-size: 12px;
        color:#000000;
        line-height: normal;
        font-family: salesforcesans-regular,sans-serif;
        margin-top: auto;
        padding-top: 10px;
      }

      .cardBodyInner {
        padding: 90px 0 0 0;

        @include media-breakpoint-down(sm) {
          margin-top: 1.25rem;
          padding: 0;
        }
      }
    }

    .expertCoachingOverview {
      line-height: 1.5em;
      max-height: 4.5em;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &.expertcoaching {
    height: 340px;

    .card-header {
      height: auto;

      .eventHeaderSub {
        height: auto;
        position: relative;
        top: 0;
        left: 0;
        padding: 1rem 1.5rem 0;

        .eventHeaderSub--item {
          text-transform: uppercase;
        }
      }
    }
    .card-body {
      padding: 1.25rem 15px;
      justify-content: start;
      flex-wrap: nowrap;

      .expertCoachingOverview {
        display: flex;
        align-items: stretch;
        flex-grow: 100;
      }

      .eventTagWrapper {
        bottom: 0;
        position: relative;
        display: flex;
        padding: 0 0 0 0;
        flex-direction: column;
        max-height: 62px;

        @include media-breakpoint-down(sm) {
          display: flex;
          min-height: 100px;
          max-height: none;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          padding: 0 0 0 118px;

          &:first-child {
            min-height: auto;
          }
        }
      }

      .cardBodyInner {
        padding: 0 0 0 0;

        @include media-breakpoint-down(sm) {
          margin-top: 1.25rem;
          padding: 0;
        }
      }

      .eyebrowLabel {
        margin: 0;
        font-size: 14px;
        line-height: 18px;

        &:after {
          content: none;
        }

        &:last-child {
          &:after {
            content: none;
          }
        }

        &.tier-tag_left {
          position: unset;
        }
      }

      .eccmetadata {
        font-size: 12px;
        color:#000000;
        line-height: normal;
        font-family: salesforcesans-regular,sans-serif;
        margin-top: auto;
        padding-top: 10px;
      }

      .eventOverview {
        color: $stratus;
        font-size: 14px;
        line-height: 21px;
        margin-top: 8px;
      }
  }
  }
}
