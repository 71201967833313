@import "../../styles/shared/_variables.scss";

.registration-form {
  text-align: left;
}

.signin-icon {
  position: absolute;
  left: 15.5px;
}

.trailblazerUserWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}

.trailblazerUserIcon {
  border: 4px solid #dddbda;
  width: 57px;
  height: 57px;
  border-radius: 57px;
  background-color: #ab47bc;
  margin-right: 13px;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f5f2f2;
}

.trailblazerUserInfoTitle {
  @include sansreg();
  color: $night;
  font-size: 22px;
  font-weight: 600;
}

.trailblazerUserInfo {
  @include sansreg();
  color: $night;
  font-size: 15px;
  font-weight: 600;
}

.signin-button {
  background: #0b5cab;
  border-radius: 8px;
  padding: 13.6px 17px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 50px;
  margin-top: 20px;
}

.validation-rules-text {
  @include sansreg();
  color: $night;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 25px;
}

.checkbox-container {
  color: #222222;
  margin-top: 40px;
  margin-bottom: 25px;
}

.complete-share-button {
  //min-width: 299px;
  margin-top: 10px !important; // "important" for override previous overriding on react-bootstrap button
  display: flex; // .eventDetailCard.card .card-body .btn
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.complete-launch-button,
.complete-try-button {
  margin-top: 0px !important; // "important" for override previous overriding on react-bootstrap button
  margin-bottom: 10px !important;
  display: flex; // .eventDetailCard.card .card-body .btn
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.complete-launch-button,
.complete-try-button,
.complete-share-button {
  min-width: 240px;
}

.complete-back-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  min-width: 240px;
  background: #00a1e0;
  color: white;
  height: 47px;
  margin: 10px;
  color: white;
  &:hover,
  &:focus,
  &:active {
    color: white;
  }
}

.complete-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.complete-share-title {
  margin-right: 10px;
  margin-top: 3px;
}

.react-add-to-calendar__dropdown {
  background-color: white;
  min-width: 236px;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 8px 8px;
  position: absolute;
  top: 20px;
  z-index: -1;
  ul {
    padding-top: 30px;
    overflow: hidden;
    li {
      text-align: initial;
      color: #222222;
      padding: 15px;
      a {
        display: block;
        width: 100%;
        color: #222222;
        &:hover {
          color: #222222;
        }
      }
      &:hover {
        cursor: pointer;
        color: #222222;
        width: 100%;
        background-color: #f4f4f4;
      }
    }
  }
}

.react-add-to-calendar {
  width: 240px;
  outline: none;
  position: relative;
  z-index: 100;
  margin-bottom: 10px;
  &:hover {
    color: white;
    border-color: #215ca0;
  }
}

.react-add-to-calendar__button {
  height: 47px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #215ca0;
  border-radius: 8px;
  color: white;
  span {
    color: white;
    font-size: 15px;
    padding-right: 10px;
  }
  &:hover {
    background: #215ca0;
    color: white;
  }
  &:after {
    content: url("../../assets/images/icon-down-arrow.svg");
  }
}

.complete-title {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #222222;
  margin-bottom: 50px;
}

.complete-text {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #222222;
  margin-bottom: 50px;

  &.top-margin {
    margin: 30px 0 20px 0;
  }
}

.error {
  border: 1px solid #c23934;
  box-shadow: 0px 0px 3px 1px #c23934;
}

.error-text {
  color: #c23934;
}
