@import "../../styles/shared/_variables.scss";

.primaryFeature,
.primaryFeature .eventCard.card {
  min-height: 590px;

  @include media-breakpoint-down(sm) {
    min-height: 290px;
  }
}

.upcomingCol {
  padding: 0;
  .primaryFeature {
    .card-body .eventTitle {
      line-height: 26px;
      font-size: 1.2rem;

      @include media-breakpoint-up(lg) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    .card-body {
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        align-items: normal;
      }
      .cardBodyInner {
        @include media-breakpoint-down(sm) {
          margin-top: 0px;
        }
      }
    }
    .cardBodyInner {
      margin-top: -30px;
    }
  }
  .no-upcoming-event_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 70px;
    max-width: 500px;
    margin: 0 auto;

    .no-upcoming-event_title {
      padding-bottom: 5px;
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      text-align: center;
      color: #9a8f87;
    }

    .no-upcoming-event_description {
      padding-top: 10px;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #59575c;
      padding-bottom: 20px;
    }

    .no-upcoming-event_link {
      font-weight: 600;
    }
  }

  .events_load-more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    transition: all 1s;
    visibility: visible;
    opacity: 1;
  }

  .events_load-more.hide {
    opacity: 0;
    visibility: hidden;
    margin-bottom: 0;
  }
}

.featureCol {
  padding: 0;
}

.featureCol {
  .eventCard.card {
    .card-header {
      background-color: $earth-mid;
      .dateCallout,
      .labelCallout,
      .vidCallout {
        background: #fff;

        &--time {
          background: $karl-the-fog;
        }
      }
    }
    .card-body {
      .eyebrowLabel.sub-tag {
        background: #fff;
      }
    }

    background-color: $earth-mid;
  }
  .primaryFeature {
    .card-body .eventTitle {
      line-height: 26px;
      font-size: 1.2rem;

      @include media-breakpoint-up(lg) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    .card-body {
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        align-items: normal;
      }
      .cardBodyInner {
        @include media-breakpoint-down(sm) {
          margin-top: 0px;
        }
      }
    }
    .cardBodyInner {
      margin-top: -30px;
    }
  }
  .no-upcoming-event_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 70px;
    max-width: 500px;
    margin: 0 auto;

    .no-upcoming-event_title {
      padding-bottom: 5px;
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      text-align: center;
      color: #9a8f87;
    }

    .no-upcoming-event_description {
      padding-top: 10px;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #59575c;
      padding-bottom: 20px;
    }

    .no-upcoming-event_link {
      font-weight: 600;
    }
  }

  .events_load-more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    transition: all 1s;
    visibility: visible;
    opacity: 1;
  }

  .events_load-more.hide {
    opacity: 0;
    visibility: hidden;
    margin-bottom: 0;
  }
}
