@font-face {
  font-family: "avantegarde-bold";
  src: url("./../../assets/fonts/90882e39-7091-4415-b231-1e068ccdcc6f.woff")
      format("woff"),
    url("./../../assets/fonts/4dd591d8-4168-4263-b05b-7183ddaff1f4.woff2")
      format("woff2");
}

@font-face {
  font-family: "salesforcesans-regular";
  src: url("./../../assets/fonts/SalesforceSans-Regular.eot");
  src: url("./../../assets/fonts/SalesforceSans-Regular.eot?") format("eot"),
    url("./../../assets/fonts/SalesforceSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "salesforcesans-bold";
  src: url("./../../assets/fonts/SalesforceSans-Bold.eot");
  src: url("./../../assets/fonts/SalesforceSans-Bold.eot?") format("eot"),
    url("./../../assets/fonts/SalesforceSans-Bold.woff") format("woff");
}

@font-face {
  font-family: "salesforcesans-light";
  src: url("./../../assets/fonts/SalesforceSans-Light.eot");
  src: url("./../../assets/fonts/SalesforceSans-Light.eot?") format("eot"),
    url("./../../assets/fonts/SalesforceSans-Light.woff") format("woff");
}
