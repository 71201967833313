@import "../../styles/shared/_variables.scss";

.promoWrapper {
  .promoContentCol {
    padding: 40px 60px;

    @include media-breakpoint-down(lg) {
      padding: 0 20px;
    }

    .btn {
      @include media-breakpoint-down(md) {
        margin-left: 0;
        margin-bottom: 20px;
        display: block;
        width: 100%;
      }
    }

    .bordered-btn {
      border-width: 2px;
      border-color: #0176D3;
    }
  }
  h3.groupHeader {
    margin-bottom: 0;
  }

  .btn {
    svg {
      margin: -4px 0 0 8px;
      height: 15px;
      path {
        transition: 0.2s all linear;
        fill: #fff;
      }
    }
  }

  &.expert-coaching {
    > .row {
      background: $karl-the-fog;
      border-radius: 15px;
      padding: 50px;
      margin: 0;

      @include media-breakpoint-down(sm) {
        padding: 40px 25px;
      }
    }

    .promoImageCol {
      padding: 0;

      @include media-breakpoint-down(sm) {
        padding-left: 15px;
      }
    }

    .promoContentCol {

      @include media-breakpoint-down(md) {
        padding: 15px;
      }
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    h3.groupHeader {
      @include media-breakpoint-down(sm) {
        padding: 40px 0 20px;
      }
    }

    h4.groupSubHeader {
      @include media-breakpoint-down(sm) {
        margin: 1.25rem 0;
      }
    }

    .groupLinkSet {
      div {
        @include media-breakpoint-down(xxs) {
          width: auto;
        }

        &:last-child {
          .product-link {
            @include media-breakpoint-down(sm) {
              margin-bottom: 0;
            }
          }
        }
      }

      @include media-breakpoint-down(xxs) {
        flex-direction: column;
        align-content: center;
      }
    }

    .product-link {
      @include agbold();
      font-size: 1rem;
      line-height: 60px;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      height: 60px;
      padding: 0 10px 0 70px;
      margin: 0 0 20px;
      background-repeat: no-repeat;
      background-position: left center;

      &.sales {
        background-image: url(../../assets/images/icon_product_sales.svg);
      }
      &.marketing {
        background-image: url(../../assets/images/icon_product_marketing.svg);
      }
      &.platform {
        background-image: url(../../assets/images/icon_product_platform.svg);
      }
      &.service {
        background-image: url(../../assets/images/icon_product_service.svg);
      }
      &.commerce {
        background-image: url(../../assets/images/icon_product_commerce.svg);
      }
      &.analytics {
        background-image: url(../../assets/images/icon_product_analytics.svg);
      }
    }
  }

  &.personalized-guidance {
    > .row {
      border-radius: 15px;
      padding: 50px 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    .align-items-center-horiz {
      justify-content: center;
      align-items: center;
    }

    .promoImageCol {

      @include media-breakpoint-down(sm) {
        padding-left: 15px;
      }
    }

    .promoContentCol {

      @include media-breakpoint-down(md) {
        padding: 15px;
      }
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    h3.groupHeader {
      @include media-breakpoint-down(sm) {
        padding: 40px 0 20px;
      }
    }

    h4.groupSubHeader {
      @include media-breakpoint-down(sm) {
        margin: 1.25rem 0;
      }
    }

    .groupLinkSet {
      div {
        @include media-breakpoint-down(xxs) {
          width: auto;
        }

        &:last-child {
          .product-link {
            @include media-breakpoint-down(sm) {
              margin-bottom: 0;
            }
          }
        }
      }

      @include media-breakpoint-down(xxs) {
        flex-direction: column;
        align-content: center;
      }
    }

    .product-link {
      @include agbold();
      font-size: 1rem;
      line-height: 60px;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      height: 60px;
      padding: 0 10px 0 70px;
      margin: 0 0 20px;
      background-repeat: no-repeat;
      background-position: left center;

      &.sales {
        background-image: url(../../assets/images/icon_product_sales.svg);
      }
      &.marketing {
        background-image: url(../../assets/images/icon_product_marketing.svg);
      }
      &.platform {
        background-image: url(../../assets/images/icon_product_platform.svg);
      }
      &.service {
        background-image: url(../../assets/images/icon_product_service.svg);
      }
      &.commerce {
        background-image: url(../../assets/images/icon_product_commerce.svg);
      }
      &.analytics {
        background-image: url(../../assets/images/icon_product_analytics.svg);
      }
    }
  }

  &.success-reviews {
    > .row {
      border-radius: 15px;
      padding: 50px 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    .align-items-center-horiz {
      justify-content: center;
      align-items: center;
    }

    .promoImageCol {
      text-align: center;
      img {
        max-width: 75%;
      }
      
      @include media-breakpoint-down(sm) {
        padding-left: 15px;
      }
    }

    .promoContentCol {
      padding: 0px 60px;
      @include media-breakpoint-down(md) {
        padding: 15px;
      }
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    h3.groupHeader {
      font-size: 40px;
      @include media-breakpoint-down(sm) {
        padding: 40px 0 20px;
      }
    }

    h4.groupSubHeader {
      @include media-breakpoint-down(sm) {
        margin: 1.25rem 0;
      }
    }

    .groupLinkSet {
      div {
        @include media-breakpoint-down(xxs) {
          width: auto;
        }

        &:last-child {
          .product-link {
            @include media-breakpoint-down(sm) {
              margin-bottom: 0;
            }
          }
        }
      }

      @include media-breakpoint-down(xxs) {
        flex-direction: column;
        align-content: center;
      }
    }

    .product-link {
      @include agbold();
      font-size: 1rem;
      line-height: 60px;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      height: 60px;
      padding: 0 10px 0 70px;
      margin: 0 0 20px;
      background-repeat: no-repeat;
      background-position: left center;

      &.sales {
        background-image: url(../../assets/images/icon_product_sales.svg);
      }
      &.marketing {
        background-image: url(../../assets/images/icon_product_marketing.svg);
      }
      &.platform {
        background-image: url(../../assets/images/icon_product_platform.svg);
      }
      &.service {
        background-image: url(../../assets/images/icon_product_service.svg);
      }
      &.commerce {
        background-image: url(../../assets/images/icon_product_commerce.svg);
      }
      &.analytics {
        background-image: url(../../assets/images/icon_product_analytics.svg);
      }
    }
  }
}
