@import "../shared/_variables.scss";

body,
html {
  background: #fff !important;
  color: $black-ice;
  @include sansreg();
  // Turn on anti-aliasing in this section
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

body {
  // offset from fixed nav
  // padding-top: 140px;

  @include media-breakpoint-down(lg) {
    padding-top: 95px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 92px;
  }
}

a,
.btn-link {
  color: $cirrus;
  text-decoration: none !important;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    text-decoration: none !important;
  }
}

h1.siteHeader {
  margin: 50px 0;
}

h3 {
  margin-bottom: 20px;
}

h2.groupHeader,
h3.groupHeader {
  @include agbold();
  color: $stratus;
  line-height: 32px;
  padding: 20px 0;
  font-size: 1.4rem;
  color: $stratus;
  margin-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }
  @include media-breakpoint-down(sm) {
    padding: 10px 0;
  }
}

h4.groupSubHeader {
  @include agbold();
  color: $stratus;
  line-height: 1.1rem;
  padding: 20px 0;
  font-size: 17px;
  color: $stratus;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(lg) {
    font-size: 17px;
  }
  @include media-breakpoint-down(sm) {
    padding: 10px 0;
  }
}

.btn {
  padding: 10px 26px;
  border-radius: 6px;
}

.btn-primary {
  @include button-variant(
    $cirrus,
    $cirrus,
    darken($cirrus, 10%),
    darken($cirrus, 10%),
    lighten($cirrus, 10%),
    lighten($cirrus, 10%)
  );
}

.btn-secondary {
  @include button-variant(#fff, #c1b9b4, #fff, $cirrus, #fff, #c1b9b4);

  @include sansreg();

  color: $cirrus;

  &:hover {
    color: $cirrus;
  }

  &.shareButton {
    svg {
      margin: -4px 0 0 4px;
      height: 15px;
      path {
        transition: 0.2s all linear;
      }
    }

    &:hover {
      svg path {
        transition: 0.2s all linear;
        fill: $cirrus;
      }
    }
  }

  &.icon-button {
    svg {
      path {
        fill: #59575c !important;
      }
    }
  }

  &.fill-mode {
    background: #f5f2f2;
    border-color: #f5f2f2;
    color: #59575c;
  }

  &.side-btn {
    margin-left: 20px;
  }
}

.icon-button {
  svg {
    margin: -4px 0 0 8px;
    height: 15px;
    path {
      transition: 0.2s all linear;
      fill: $cirrus;
    }
  }
}

.container {
  @include media-breakpoint-down(xl) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.colPanel {
  //background: #f4f4f4;
  padding: 20px 0;
  min-height: 200px;
}

.mainContent {
  min-height: 100vh;
}

.insetPage {
  padding: 40px 0;
  background: #fff;

  @include media-breakpoint-down(lg) {
    padding: 15px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.fullPanel {
  background: #fff;
}

.listTileContainer {
  min-height: 100vh;
  //padding-bottom: 100px;
}

.bandedSection {
  &--gray {
    background: #f4f4f4;
    .colPanel {
      background: #f4f4f4;
    }
  }
  &--white {
    background: #fff;
    .colPanel {
      background: #fff;
    }
  }
  &--blue {
    background: $cloud;
    .colPanel,
    .jumbotron {
      background: $cloud;
    }
  }
}

.groupLinkSet {
  a {
    @include sansbold();
    margin: 0 30px 10px 0;
    white-space: nowrap;
  }
}

.richText {
  white-space: pre-line;
}
