@import "../../../styles/shared/_variables.scss";

.coveo--result-header {
    margin-top: 38px;

    .result-summary {
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        > div {
            margin-right: 30px;
        }

        .btn {
            flex: 0 0 auto;
            box-shadow: none;
            padding: 0;
        }
    }

    @include media-breakpoint-down(md) {
        margin-top: 1.5rem;
    }
}