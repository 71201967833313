@import "../../styles/shared/_variables.scss";

.loginPageWrapper {
  min-height: 100vh;
  position: relative;
  padding-top: 100px;

  .heroBanner .heroContainer {
    min-width: 310px;
  }
  
  .heroBanner .heroContainer h1 {
    margin-bottom: 20px;
  }

  .heroBanner .maskedBg {
    background-color: white;
    left: 30%;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      img {
        margin-top: 240px;
        left: 20vw;
      }
    }
  }

  .heroBanner {
    @include media-breakpoint-down(sm) {
      overflow: visible;
      min-height: 0px;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 40px;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 30px;
  }
}

.loginPageP {
  max-width: 270px;
  margin-top: 30px;
  line-height: 1.6;
}
